/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { IDysphagiaDetailProps } from "./IDysphagiaDetail";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  TextBox,
  DateBox,
  SelectBox,
  Popup,
  CheckBox,
} from "devextreme-react";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  ArgumentAxis,
  Label,
  Legend,
  Tooltip,
  Format,
} from "devextreme-react/chart";
import * as _ from "lodash";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import sBox from "devextreme/ui/select_box";
import dBox from "devextreme/ui/date_box";
import tBox from "devextreme/ui/text_box";
import { alert, confirm } from "devextreme/ui/dialog";
import DataGrid, {
  Column,
  Pager,
  Paging,
  LoadPanel,
} from "devextreme-react/data-grid";
import moment from "moment";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);
locale("" + localStorage.getItem("lang"));

// // name을 제외하고 sessionStorage를 초기화 하는 함수
// function clearSessionStorageExceptName(): void {
//   // "name"에 해당하는 값을 가져오기
//   const nameValue = sessionStorage.getItem("id");

//   // sessionStorage를 비우기
//   sessionStorage.clear();

//   // 다시 "name" 값을 설정하기 (다른 데이터가 없다면 비워진 상태로 설정될 것입니다)
//   sessionStorage.setItem("id", nameValue || "");
// }

export const PatientDetailProps: FunctionComponent<IDysphagiaDetailProps> = (
  props
) => {
  const history = useHistory();
  const common = new Common();
  const param: any = useParams();
  const [daterangeState, setDateRangeState] = useState("D");
  const [dysphagiaState, setDysphagiaState] = useState("A");
  const [startMinDate, setStartMinState] = useState<Date | undefined>(
    undefined
  );
  const [startMaxDate, setStartMaxState] = useState<Date | undefined>(
    undefined
  );
  const [endMinDate, setEndMinState] = useState<Date | undefined>(undefined);
  const [endMaxDate, setEndMaxState] = useState<Date | undefined>(undefined);
  const [endDateBoxState, setEndDateBoxState] = useState(false);
  const [startLevelState, setStartLevelState] = useState(false);
  const [endLevelState, setEndLevelState] = useState(false);
  const [PatientInfo, setPatientInfo] = useState({} as any);
  const [List, setList] = useState({} as any);
  const [isDisplayPopupVisible, setDisplayPopupVisibility] = useState(false);
  const [NoC, setNoColumnState] = useState(true);
  const [MeasureC, setMeasureColumnState] = useState(true);
  const [GNC, setGNState] = useState(true);
  const [GLC, setGLState] = useState(true);

  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [GameInfo, setGameInfo] = useState({} as any);
  const [Period, setPeriod] = useState([] as any);
  const [MaxDysphagia, setMaxDysphagia] = useState({} as any);
  const [BodyTemp, setBodyTemp] = useState({} as any);
  const [ChartData, setChartData] = useState([] as any);
  const [MainChartData, setPaginatedData] = useState([] as any);
  const [PageIndex, setPageIndexState] = useState(0);
  const [PageSize, setPageSizeState] = useState(10);
  // const dataGridRef = useRef<DataGrid | null>(null);
  // const chartRef = useRef<Chart | null>(null);

  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    common.ResizePage();

    setStartMinState(moment("2023-01-01").toDate());
    setStartMaxState(moment("2050-12-31").toDate());
    setEndMinState(moment("2023-01-01").toDate());
    setEndMaxState(moment("2050-12-31").toDate());

    if (sessionStorage.getItem("id") !== null) {
      const adminService = new AdminDataProvider();
      if (sessionStorage.getItem("d_startDate")) {
        const dateRangeState = sessionStorage.getItem("d_daterangeState");
        const dysphagiaState = sessionStorage.getItem("d_dysphagiaState");
        const endDateBoxState = sessionStorage.getItem("d_endDateBoxState");
        setDateRangeState(dateRangeState !== null ? dateRangeState : "D");
        setDysphagiaState(dysphagiaState !== null ? dysphagiaState : "A");
        setEndDateBoxState(
          endDateBoxState !== null ? JSON.parse(endDateBoxState) : ""
        );
        valToTag(
          "startDate",
          dBox,
          adminService.dateFormat(sessionStorage.getItem("d_startDate"))
        );
        valToTag(
          "endDate",
          dBox,
          adminService.dateFormat(sessionStorage.getItem("d_endDate"))
        );
        valToTag(
          "startslevel",
          tBox,
          sessionStorage.getItem("d_startSevereLevel")
        );
        valToTag("endslevel", tBox, sessionStorage.getItem("d_endSevereLevel"));

        const json = {
          patientId: param.id,
          offset: 0,
          limit: 5000,
          startDate: adminService.dateFormat(
            sessionStorage.getItem("d_startDate")
          ),
          endDate: adminService.dateFormat(sessionStorage.getItem("d_endDate")),
          startSevereLevel: sessionStorage.getItem("d_startSevereLevel"),
          endSevereLevel: sessionStorage.getItem("d_endSevereLevel"),
        };
        adminService.getDysphagiaPatientDetail(json).then(async (e: any) => {
          console.log(e);

          let list = e.data.data.measureInfo;

          list.forEach((patient: { input_utc_dt: moment.MomentInput }) => {
            patient.input_utc_dt = moment(patient.input_utc_dt).format(
              "DD/MM/YYYY HH:mm:ss"
            );
          });

          setPatientInfo(e.data.data.patientInfo[0]);
          setList(list);

          // 메인차트 배열 만드는 부분
          // Calculate start and end indices for pagination
          const startIndex = PageIndex * PageSize;
          const endIndex = startIndex + PageSize;

          // Extract paginated data from the original array
          const slicedData = list.slice(startIndex, endIndex);

          // Set the paginated data in the state
          setPaginatedData(slicedData);
        });

        common.ResizePage();
        if (dateRangeState === "D") {
          setStartMinState(moment("2023-01-01").toDate());
          setStartMaxState(_dev("endDate", dBox).option("value"));
          setEndMinState(_dev("startDate", dBox).option("value"));
          setEndMaxState(moment("2050-12-31").toDate());
        }
      } else {
        btnSearch("");
        setStartMinState(moment("2023-01-01").toDate());
        setStartMaxState(_dev("endDate", dBox).option("value"));
        setEndMinState(_dev("startDate", dBox).option("value"));
        setEndMaxState(moment("2050-12-31").toDate());
      }
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  // 날짜 범위 셀렉트 박스 항목
  const selectBoxData = [
    {
      id: 1,
      name: "Day",
      value: "D",
    },
    {
      id: 2,
      name: "Week",
      value: "W",
    },
    {
      id: 3,
      name: "Month",
      value: "M",
    },
  ];

  // game 셀렉트 박스 항목
  const selectBoxData2 = [
    {
      id: 1,
      name: "ALL",
      value: "A",
    },
    {
      id: 2,
      name: "High (81~100%)",
      value: "H",
    },
    {
      id: 3,
      name: "Middle (31~80%)",
      value: "M",
    },
    {
      id: 4,
      name: "Low (0~30%)",
      value: "L",
    },
  ];

  // 날짜 범위 설정을 셀렉트 박스로 설정하였을때
  const setDate = () => {
    const dRangeVal = _dev("daterange", sBox).option("value");
    if (dRangeVal === "D") {
      setEndDateBoxState(false);
      valToTag("startDate", dBox, moment());
      valToTag("endDate", dBox, moment());
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(_dev("endDate", dBox).option("value"));
      setEndMinState(_dev("startDate", dBox).option("value"));
      setEndMaxState(moment("2050-12-31").toDate());
      setDateRangeState("D");
    } else if (dRangeVal === "W") {
      setEndDateBoxState(true);
      valToTag("startDate", dBox, moment().add(-7, "days"));
      valToTag("endDate", dBox, moment());
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(moment().add(-7, "days").toDate());
      setEndMinState(moment("2023-01-01").toDate());
      setDateRangeState("W");
    } else if (dRangeVal === "M") {
      setEndDateBoxState(true);
      valToTag("startDate", dBox, moment().startOf("month"));
      valToTag("endDate", dBox, moment().endOf("month"));
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(moment().toDate());
      setEndMinState(moment("2023-01-01").toDate());
      setDateRangeState("M");
    } else {
      setEndDateBoxState(false);
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(_dev("endDate", dBox).option("value"));
      setEndMinState(_dev("startDate", dBox).option("value"));
      setEndMaxState(moment("2050-12-31").toDate());
      setDateRangeState("D");
    }
  };

  // 레벨을 셀렉트 박스로 설정하였을때
  const setLevel = () => {
    const dLevel = _dev("dysphagia", sBox).option("value");
    if (dLevel === "A") {
      setStartLevelState(false);
      setEndLevelState(false);

      valToTag("startslevel", tBox, "");
      valToTag("endslevel", tBox, "");
      setDysphagiaState("A");
    } else if (dLevel === "H") {
      setStartLevelState(true);
      setEndLevelState(true);
      valToTag("startslevel", tBox, "81");
      valToTag("endslevel", tBox, "100");
      setDysphagiaState("H");
    } else if (dLevel === "M") {
      setStartLevelState(true);
      setEndLevelState(true);
      valToTag("startslevel", tBox, "31");
      valToTag("endslevel", tBox, "80");
      setDysphagiaState("M");
    } else if (dLevel === "L") {
      setStartLevelState(true);
      setEndLevelState(true);
      valToTag("startslevel", tBox, "0");
      valToTag("endslevel", tBox, "30");
      setDysphagiaState("L");
    } else {
      setStartLevelState(false);
      setEndLevelState(false);
      valToTag("startslevel", tBox, "");
      valToTag("endslevel", tBox, "");
      setDysphagiaState("A");
    }
  };

  // reset 버튼을 눌렀을때 함수
  const resetSearch = () => {
    setEndDateBoxState(false);
    setStartMinState(moment("2023-01-01").toDate());
    setStartMaxState(moment("2050-12-31").toDate());
    setEndMinState(moment("2023-01-01").toDate());
    setEndMaxState(moment("2050-12-31").toDate());
    setDateRangeState("D");
    valToTag("startDate", dBox, moment());
    valToTag("endDate", dBox, moment());
    setDysphagiaState("A");
    valToTag("startslevel", tBox, "");
    valToTag("endslevel", tBox, "");
  };

  // datebox 태그의 value 값을 다른 value로 넣는 함수
  const valToTag = (e: any, box: any, value: any) => {
    const id = document.getElementById(e) as HTMLElement;
    const instance = box.getInstance(id);
    instance.option("value", value);
  };

  // 태그의 정보를 불러오는 함수
  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };

  // startDate 선택시 endDate 값 변경
  const funcSetEndDate = () => {
    const dRangeVal = _dev("daterange", sBox).option("value");
    const startDate = _dev("startDate", dBox).option("value");
    if (dRangeVal === "W") {
      valToTag("endDate", dBox, moment(startDate).add(7, "days"));
    } else if (dRangeVal === "M") {
      valToTag("endDate", dBox, moment(startDate).endOf("month"));
    } else {
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(_dev("endDate", dBox).option("value"));
      setEndMinState(_dev("startDate", dBox).option("value"));
      setEndMaxState(moment("2050-12-31").toDate());
    }
  };

  // endDate 선택시 endDate 값 변경
  const funcSetStartDate = () => {
    const dRangeVal = _dev("daterange", sBox).option("value");
    if (dRangeVal === "D") {
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(_dev("endDate", dBox).option("value"));
      setEndMinState(_dev("startDate", dBox).option("value"));
      setEndMaxState(moment("2050-12-31").toDate());
    }
  };

  // 검색버튼 클릭 데이터 요청
  const btnSearch = async (d: any) => {
    const adminService = new AdminDataProvider();
    const offset = 0;
    const limit = 5000;
    const startDate = _dev("startDate", dBox).option("value");
    const endDate = _dev("endDate", dBox).option("value");
    const startslevel = _dev("startslevel", tBox).option("value");
    const endslevel = _dev("endslevel", tBox).option("value");

    const json = {
      patientId: param.id,
      offset: offset,
      limit: limit,
      startDate: adminService.dateFormat(startDate),
      endDate: adminService.dateFormat(endDate),
      startSevereLevel: startslevel,
      endSevereLevel: endslevel,
      // gameName: game,
    };

    adminService.getDysphagiaPatientDetail(json).then(async (e: any) => {
      console.log(e);

      let list = e.data.data.measureInfo;

      list.forEach((patient: { input_utc_dt: moment.MomentInput }) => {
        patient.input_utc_dt = moment(patient.input_utc_dt).format(
          "DD/MM/YYYY HH:mm:ss"
        );
      });

      setPatientInfo(e.data.data.patientInfo[0]);
      setList(list);

      // 메인차트 배열 만드는 부분
      // Calculate start and end indices for pagination
      const startIndex = PageIndex * PageSize;
      const endIndex = startIndex + PageSize;

      // Extract paginated data from the original array
      const slicedData = list.slice(startIndex, endIndex);

      // Set the paginated data in the state
      setPaginatedData(slicedData);
    });
    common.ResizePage();
  };

  // 재활치료 게임 상세 데이터 요청
  const getGameDetail = async (e: any) => {
    const gameinfo = e.data;

    console.log(gameinfo);
    // Click 된 Row에서 가져온 데이터를 state에 저장
    setGameInfo(gameinfo);

    const adminService = new AdminDataProvider();
    const json = {
      seq: gameinfo.seq,
    };

    adminService.getDysphagiasGameDetail(json).then(async (e: any) => {
      const data = e.data.data;
      console.log("detail: ", data);

      // Measurement Period 만들기
      const minDate = new Date(
        Math.min(
          ...data.patientInfo.map(
            (item: { input_utc_dt: string | number | Date }) =>
              new Date(item.input_utc_dt)
          )
        )
      );
      const maxDate = new Date(
        Math.max(
          ...data.patientInfo.map(
            (item: { input_utc_dt: string | number | Date }) =>
              new Date(item.input_utc_dt)
          )
        )
      );

      const periodArray = [minDate.toISOString(), maxDate.toISOString()];

      // Max Dysphagia 만들기
      const patientInfoArray: Array<{
        seq: number;
        patient_id: string;
        input_utc_dt: string;
        timezone_offset: number;
        severe_level: number;
      }> = data.patientInfo;

      // 배열을 severe_level 내림차순, input_utc_dt 내림차순으로 정렬
      const sortedData = patientInfoArray.sort((a, b) => {
        if (a.severe_level !== b.severe_level) {
          return b.severe_level - a.severe_level;
        } else {
          return (
            new Date(b.input_utc_dt).getTime() -
            new Date(a.input_utc_dt).getTime()
          );
        }
      });

      // 첫 번째 요소를 가져와 JSON 형태로 만들기
      const mostRecentHighestSevereLevel = sortedData[0];

      const maxDysphagiaJSON = JSON.stringify(
        mostRecentHighestSevereLevel,
        null,
        2
      );

      // Body Temperature 만들기
      const temperatureArray: Array<{
        seq: number;
        patient_id: string;
        temperature_c: number;
        temperature_f: number;
        input_utc_dt: string;
        timezone_offset: number;
      }> = data.temperature;

      // "patient_id"가 "seo"인 사람 필터링
      const filteredData = temperatureArray.filter(
        (item) => item.patient_id === gameinfo.patient_id
      );

      // 결과를 JSON으로 만들기
      const temperatureJSON = JSON.stringify(filteredData[0], null, 2);

      // 두 배열을 합치기
      const combinedArray = [...patientInfoArray, ...temperatureArray];

      // input_utc_dt를 기준으로 정렬
      combinedArray.sort((a, b) =>
        moment(a.input_utc_dt).diff(moment(b.input_utc_dt))
      );

      // 날짜 형식을 "YYYY-MM-DD"로 변환
      const formattedArray = combinedArray.map((item) => ({
        ...item,
        input_utc_dt: moment(item.input_utc_dt).format("YYYY-MM-DD hh:mm"),
      }));

      setPeriod(periodArray);
      setMaxDysphagia(
        maxDysphagiaJSON === undefined ? [] : JSON.parse(maxDysphagiaJSON)
      );
      setBodyTemp(
        temperatureJSON === undefined ? [] : JSON.parse(temperatureJSON)
      );

      interface PatientData {
        seq: number;
        patient_id: string;
        temperature_c?: number;
        temperature_f?: number;
        input_utc_dt: string;
        timezone_offset: number;
        severe_level?: number;
      }

      // 'input_utc_dt'를 기준으로 배열을 정렬
      const sortedArray: PatientData[] = formattedArray.sort((a, b) =>
        a.input_utc_dt.localeCompare(b.input_utc_dt)
      );

      // 결과 출력
      console.log(sortedArray);
      setChartData(sortedArray);
    });
  };

  // 컬럼 출력 설정 팝업 켜기/끄기
  const openDisplayPopup = (e: any) => {
    setDisplayPopupVisibility(!isDisplayPopupVisible);
  };

  // No 컬럼 출력 변경
  const changeNoCValue = (e: any) => {
    setNoColumnState(!NoC);
  };

  // 측정일자 컬럼 출력 변경
  const changeMeasureCValue = (e: any) => {
    setMeasureColumnState(!MeasureC);
  };

  // 게임이름 컬럼 출력 변경
  const changeGNCValue = (e: any) => {
    setGNState(!GNC);
  };

  // 게임레벨 컬럼 출력 변경
  const changeGLCValue = (e: any) => {
    setGLState(!GLC);
  };

  // 환자 게임 상세 팝업 켜기/끄기
  const openGameInfoModal = () => {
    setPopupVisibility(!isPopupVisible);
  };

  const renderContent = () => {
    return (
      <div>
        <CheckBox
          text="No"
          defaultValue={NoC}
          onValueChanged={changeNoCValue}
        />
        <br />
        <CheckBox
          text="Measurement Date"
          defaultValue={MeasureC}
          onValueChanged={changeMeasureCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="Dysphagia Severity (%)"
          defaultValue={GNC}
          onValueChanged={changeGNCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="Emergency"
          defaultValue={GLC}
          onValueChanged={changeGLCValue}
          style={{ marginTop: "10px" }}
        />
      </div>
    );
  };

  const renderContentChart = () => {
    return (
      <div style={{ height: "550px" }}>
        <div style={{ display: "flex", height: "100%" }}>
          <div
            style={{
              width: "38%",
              padding: "10px",
              border: "1px solid #0373c6",
            }}
          >
            <h2>
              Measurement Date:
              <br />
              {GameInfo.input_utc_dt}
              <br />
              <br />
              PatientId: {GameInfo.patient_id}
              <br />
              <br />
              Measurement Period: <br />
              {moment(Period[0]).format("DD / MM / YYYY")} ~{" "}
              {moment(Period[1]).format("DD / MM / YYYY")}
              <br />
              <br />
              Max Dysphagia:
              <br /> {MaxDysphagia.severe_level}
              {"% ("}
              {moment(MaxDysphagia.input_utc_dt).format("DD/MM/YYYY HH:mm:ss")}
              {")"}
              <br />
              <br />
              Body Temperature:
              <br /> {BodyTemp.temperature_c}
              {BodyTemp.temperature_f === undefined ||
              BodyTemp.temperature_f === null ||
              BodyTemp.temperature_f === ""
                ? "-"
                : "\u2103 ("}
              {BodyTemp.temperature_f}
              {BodyTemp.temperature_f === undefined ||
              BodyTemp.temperature_f === null ||
              BodyTemp.temperature_f === ""
                ? null
                : "\u2109)  "}
              {BodyTemp.temperature_f === undefined ||
              BodyTemp.temperature_f === null ||
              BodyTemp.temperature_f === ""
                ? null
                : `(${moment(BodyTemp.input_utc_dt).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )})`}
            </h2>
          </div>
          <div
            style={{
              width: "59%",
              marginLeft: "10px",
              border: "1px solid red",
              padding: "10px",
            }}
          >
            <Chart
              dataSource={ChartData}
              id="chart"
              style={{ height: "95%", width: "99%" }}
              title={`${param.id}(${PatientInfo.source + PatientInfo.seq})`}
            >
              <CommonSeriesSettings
                argumentField="chart"
                type="spline"
                hoverMode="allArgumentPoints"
                selectionMode="allArgumentPoints"
              >
                <Label visible={true}>
                  <Format type="fixedPoint" precision={0} />
                </Label>
              </CommonSeriesSettings>
              {/* <CommonSeriesSettings type="spline" /> */}
              <Tooltip enabled={true} />
              <ArgumentAxis>
                <Label format="decimal" />
              </ArgumentAxis>

              <Series
                argumentField="input_utc_dt"
                valueField="severe_level"
                name="Severe Level"
              />

              <Series
                argumentField="input_utc_dt"
                valueField="temperature_c"
                name="Body Temperature"
              />

              <Legend verticalAlignment="bottom" horizontalAlignment="center" />
            </Chart>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: "10px",
            height: "110px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button width={200} height={50} onClick={openGameInfoModal}>
            Close
          </Button>
        </div>
      </div>
    );
  };

  const handlePageIndexChange = (e: any) => {
    setPageIndexState(e);
    // Calculate start and end indices for pagination
    const startIndex = e * PageSize;
    const endIndex = startIndex + PageSize;

    // Extract paginated data from the original array
    const slicedData = List.slice(startIndex, endIndex);

    // Set the paginated data in the state
    setPaginatedData(slicedData);
  };

  const handlePageSizeChange = (e: any) => {
    setPageSizeState(e);
    // Calculate start and end indices for pagination
    const startIndex = PageIndex * e;
    const endIndex = startIndex + e;

    // Extract paginated data from the original array
    const slicedData = List.slice(startIndex, endIndex);

    // Set the paginated data in the state
    setPaginatedData(slicedData);
  };

  return (
    <main id="content" className="content">
      <section className="sub-top">
        <Popup
          width={250}
          height={400}
          title="Column Chooser"
          visible={isDisplayPopupVisible}
          contentRender={renderContent}
          onHiding={openDisplayPopup}
        />
        <Popup
          width={1100}
          height={750}
          title="Patient Info"
          visible={isPopupVisible}
          onHiding={openGameInfoModal}
          contentRender={renderContentChart}
        />
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="/main">
                  <i className="fa fa-home" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a href="/dysphagia">
                  <span>Dysphagia</span>
                </a>
              </li>
              <li className="current">
                <span>Patient Info</span>
              </li>
            </ul>
          </div>
        </header>

        <article
          className="reg-form insert"
          style={{ borderTop: "1px solid #0373c6" }}
        >
          <form action="" name="regForm">
            <fieldset>
              <article>
                <div
                  className="leftcont"
                  style={{
                    color: "#0373c6",
                    border: "1px solid #0373c6",
                    padding: "10px",
                  }}
                >
                  <i className="fa fa-user icon-img fa-2x"></i>&nbsp;
                  <strong style={{ fontSize: "30px" }}>Patient Info</strong>
                  <br />
                  <strong>PatientId: {param.id}</strong>
                  <br />
                  <strong>
                    PlatformId: {PatientInfo.source + PatientInfo.seq}
                  </strong>
                  <br />
                  <strong>
                    Age: {moment().diff(PatientInfo.dob, "years")}
                  </strong>
                  <br />
                  <strong>
                    Sex: {PatientInfo.gender === "M" ? "Male" : "Female"}
                  </strong>
                </div>

                <div className="cont" style={{ marginLeft: "10px" }}>
                  <div className="contline">
                    <SelectBox
                      id="daterange"
                      dataSource={selectBoxData}
                      defaultValue={selectBoxData[0]}
                      displayExpr="name"
                      onValueChanged={setDate}
                      valueExpr="value"
                      value={daterangeState}
                      style={{ width: "160px", marginRight: "5px" }}
                    ></SelectBox>
                    <DateBox
                      id="startDate"
                      className="prs-datebox"
                      defaultValue={moment()}
                      displayFormat={"yyyy-MM-dd"}
                      onValueChanged={funcSetEndDate}
                      min={startMinDate}
                      max={startMaxDate}
                    ></DateBox>
                    <div style={{ paddingTop: "4px" }}>&#126;</div>
                    <DateBox
                      id="endDate"
                      className="prs-datebox"
                      defaultValue={moment()}
                      displayFormat={"yyyy-MM-dd"}
                      disabled={endDateBoxState}
                      onValueChanged={funcSetStartDate}
                      min={endMinDate}
                      max={endMaxDate}
                    ></DateBox>
                  </div>
                  <div className="contline">
                    <SelectBox
                      id="dysphagia"
                      dataSource={selectBoxData2}
                      onValueChanged={setLevel}
                      defaultValue=""
                      displayExpr="name"
                      valueExpr="value"
                      value={dysphagiaState}
                      placeholder="Dysphagia Severity"
                      style={{ width: "300px", marginRight: "10px" }}
                    ></SelectBox>
                    <TextBox
                      id="startslevel"
                      placeholder="%"
                      defaultValue={""}
                      disabled={startLevelState}
                      style={{ width: "190px", marginRight: "10px" }}
                    />
                    <div style={{ paddingTop: "4px" }}>&#126;</div>
                    <TextBox
                      id="endslevel"
                      placeholder="%"
                      defaultValue={""}
                      disabled={endLevelState}
                      style={{
                        width: "190px",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    />
                    <Button
                      style={{ marginRight: "10px" }}
                      text="(R)"
                      type="normal"
                      onClick={resetSearch}
                    />
                    <Button
                      width={50}
                      icon="fa fa-search"
                      type="normal"
                      onClick={btnSearch}
                    />
                  </div>
                </div>
              </article>
            </fieldset>
          </form>
        </article>
      </section>

      <section>
        <header
          style={{
            display: "flex",
            width: "100%",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        ></header>

        <section className="sub-cont__wrap">
          <article style={{ width: "48%" }}>
            <div className="tbl-wrap">
              <div style={{ width: "100%", textAlign: "right" }}>
                <Button text="Display" onClick={openDisplayPopup} />
              </div>

              <DataGrid
                style={{ marginTop: "10px" }}
                id="gridCont1"
                dataSource={List}
                columnHidingEnabled={true}
                noDataText="No data"
                selection={{ mode: "single" }}
                hoverStateEnabled={true}
                allowColumnResizing={false}
                className="type-ct"
                showBorders
                onRowClick={(e) => {
                  openGameInfoModal();
                  getGameDetail(e);
                }}
              >
                <LoadPanel enabled />
                <Column
                  caption="No"
                  width={50}
                  // cellRender={(e) => {
                  //   return e.row.rowIndex + 1;
                  // }}
                  cellRender={(e) => {
                    const pageIndex = e.component.pageIndex();
                    const pageSize = e.component.pageSize();
                    const dataIndex = e.row.dataIndex;
                    const calculatedNo = pageIndex * pageSize + dataIndex + 1;
                    return calculatedNo;
                  }}
                  visible={NoC}
                />
                <Column
                  dataField="input_utc_dt"
                  caption="Measurement Date"
                  visible={MeasureC}
                />
                <Column
                  width={170}
                  dataField="severe_level"
                  caption="Dysphagia Severity (%)"
                  visible={GNC}
                />
                <Column
                  width={150}
                  dataField="severe_level"
                  caption="Emergency"
                  visible={GLC}
                  cellRender={(e) => {
                    return e.data.severe_level <= 100 &&
                      e.data.severe_level >= 81 ? (
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        High
                      </span>
                    ) : e.data.severe_level <= 80 &&
                      e.data.severe_level >= 31 ? (
                      <span style={{ color: "brown", fontWeight: "bold" }}>
                        Middle
                      </span>
                    ) : e.data.severe_level <= 30 &&
                      e.data.severe_level >= 0 ? (
                      <span style={{ color: "green", fontWeight: "bold" }}>
                        Low
                      </span>
                    ) : (
                      <span></span>
                    );
                  }}
                />
                <Paging
                  onPageIndexChange={handlePageIndexChange}
                  onPageSizeChange={handlePageSizeChange}
                  defaultPageSize={10}
                />
                <Pager
                  visible={true}
                  allowedPageSizes={[10, 20]}
                  displayMode={"full"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              </DataGrid>
            </div>
          </article>
          <article style={{ width: "48%" }}>
            <Chart
              dataSource={MainChartData}
              id="chart"
              style={{ height: "500px", width: "100%" }}
              title={`${param.id}(${PatientInfo.source + PatientInfo.seq})`}
            >
              <CommonSeriesSettings type="spline" />
              <Tooltip enabled={true} />
              <ArgumentAxis>
                <Label format="decimal" />
              </ArgumentAxis>
              <Series
                argumentField="input_utc_dt"
                valueField="severe_level"
                name="Severe Level"
              />

              <Legend verticalAlignment="bottom" horizontalAlignment="center" />
            </Chart>
          </article>
        </section>
      </section>
    </main>
  );
};

export default PatientDetailProps;
