import { FunctionComponent, useEffect } from "react";

import "devextreme/dist/css/dx.light.css";

import "../../css/font-awesome.min.css";
import "../../css/layout.css";
import "../../css/custom.css";
import $ from "jquery";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import { IMainProps } from "./IMain";

import { lang } from "../../lib/utils/language";
import { locale, loadMessages } from "devextreme/localization";
loadMessages(lang);

// name을 제외하고 sessionStorage를 초기화 하는 함수
function clearSessionStorageExceptName(): void {
  // "name"에 해당하는 값을 가져오기
  const nameValue = sessionStorage.getItem("id");

  // sessionStorage를 비우기
  sessionStorage.clear();

  // 다시 "name" 값을 설정하기 (다른 데이터가 없다면 비워진 상태로 설정될 것입니다)
  sessionStorage.setItem("id", nameValue || "");
}

export const Main: FunctionComponent<IMainProps> = (props) => {
  locale("" + localStorage.getItem("lang"));
  const history = useHistory();
  const common = new Common();

  useEffect(() => {
    common.ResizePage();

    if (sessionStorage.getItem("id") !== null) {
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  return (
    <main id="content" className="content">
      <section className="main-area">
        <div style={{ display: "flex", marginTop: "125px" }}>
          <div
            style={{
              width: "48%",
              height: "500px",
              border: "1px solid silver",
              color: "#0373c6",
              fontSize: "30px",
              cursor: "pointer",
              textAlign: "center",
              position: "relative",
            }}
            onClick={() => {
              history.push("/patient");
              clearSessionStorageExceptName();
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-40%, -50%)",
              }}
            >
              <i className="fa fa-calendar icon-img fa-2x"></i>
              <br />
              <br />
              <strong>PSERSONALIZED</strong>
              <br />
              <strong>REHABILITATION</strong>
              <br />
              <strong>SYSTEM</strong>
            </div>
          </div>
          <div
            style={{
              marginLeft: "25px",
              width: "48%",
              height: "500px",
              border: "1px solid silver",
              color: "#0373c6",
              fontSize: "30px",
              cursor: "pointer",
              textAlign: "center",
              position: "relative",
            }}
            onClick={() => {
              history.push("/dysphagia");
              clearSessionStorageExceptName();
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "40%",
                left: "50%",
                transform: "translate(-40%, -50%)",
              }}
            >
              <i className="fa fa-user-o icon-img fa-2x"></i>
              <br />
              <br />
              <strong>DYSPHAGIA</strong>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Main;
